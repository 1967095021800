import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { ProgressBar } from 'primereact/progressbar';
// import { BaseError, useWriteContract } from "wagmi";
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useDispatch, useSelector } from "react-redux";
import ifflogo from '../../Assests/Images/ifflogo.png'
import Web3 from "web3";
import {
  GetActiveRefundFun,
  GetPhaseHandler,
  Getlatestuser,
  GetlatestuserAll,
  IFFTranferHandler,
  SellIFFHandler,
  handleApproveAndTransfer,
  startLoading,
  transferIFFHandler,
} from "../../redux/auth/auth";
import {
  contractABI,
  contractAddress,
  usdtContractAbi,
  usdtContractAddress,
} from "../../common/common";
import Privacypdf from "../../Assests/pdf/Privacy_Statement.pdf";
import termsandconditionspdf from "../../Assests/pdf/Terms_and_Conditions.pdf";
import { useParams } from "react-router-dom";
import TranscationHistory from "../../pages/TranscationHistory";
import { YourApp } from "../customwallet/CustomWallet";
import InvestCard from "./InvestCard";

function InvestmentModal() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [buyOrSell, setBuyOrSell] = useState("buy");
  const [tokenAmount, setTokenAmount] = useState("");
  const [iffAmount, setIffAmount] = useState("");
  const [activeBuySell, setActiveBuySell] = useState('')
  const { data: hash, error, writeContract } = useWriteContract()
  const [usdttoken, setUsdtToken] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("");
  const [airdroped, setAirdroped] = useState("")
  const [sellUsdtPercentage, setsellUsdtPercentage] = useState('')
  const [usdtError, setUsdtError] = useState("");
  const [iffError, setIffError] = useState("");
  const [usdtData, setUsdData] = useState();
  const [errors, setErrors] = useState({});
  const [rewardPercenteage, setRewardPercentage] = useState(0)
  const [rewardState, setRewarState] = useState()
  const [targetReward, setTargetReward] = useState()
  const [targetAmount, setTargetAmount] = useState()
  const [targetAmountAdmin, setTargetAmountAdmin] = useState()
  const [buyInactiveAmount, setBuyInactiveAmount] = useState()
  const rewardPercentage = 10;
  const [pdferrror, setPdferror] = useState({
    termsandconditions: "",
    privacypolicy: "",
  });
  const { value } = useParams();
  const metaaddress = [localStorage.getItem('meta')]
  let claimaddreess = localStorage.getItem("meta")

  const [selectCheckbox, setSelectCheckbox] = useState({
    termsandconditions: false,
    privacypolicy: false,
  });
  const [newvalue, setNewValue] = useState(0);
  const [targetState, setstateTarget] = useState(false);
  const interval = useRef(null);

  const { web3 } = useSelector((state) => state.slice);
  const { GetPhaselist, GETAirdropAdminHandler, isLoading, targetUser, refundStatus, getClaimdata, targetUserAll } = useSelector(
    (state) => state.slice
  );

  useEffect(() => {
    GetPhaselist?.map((val) => {
      if (val.status == "ACTIVE") {
        return (
          setTargetAmountAdmin(val?.targetValue)
        );
      }

    })
  }, [GetPhaselist])

  useEffect(() => {
    if (GetPhaselist) {
      GetPhaselist.forEach((val) => {
        if (val.status === "ACTIVE") {
          setNewValue(targetUserAll?.ALL_USDT / val.targetValue * 100);
          setBuyInactiveAmount(val.targetValue)
        } else {
          setstateTarget(true)
        }
      });
    }
  }, [GetPhaselist, targetUser]);

  console.log(airdroped, 'targetUser.total_buy_token + airdroped')
  // useEffect(() => {
  //   if (targetUser.total_buy_token + airdroped >= targetUser?.target_value) {
  //     setTargetAmount(airdroped + 100)
  //     // setTargetReward(targetUser?.bonus + 100)
  //     setTargetReward(
  //       Number(targetUser.total_buy_token) + Number(airdroped) >= 10000 && Number(targetUser.total_buy_token) + Number(airdroped) < 20000 ? 100
  //         : Number(targetUser.total_buy_token) + Number(airdroped) >= 20000 && Number(targetUser.total_buy_token) + Number(airdroped) < 30000 ? 200
  //           : Number(targetUser.total_buy_token) + Number(airdroped) >= 30000 && Number(targetUser.total_buy_token) + Number(airdroped) < 40000 ? 300
  //             : Number(targetUser.total_buy_token) + Number(airdroped) >= 40000 && Number(targetUser.total_buy_token) + Number(airdroped) < 50000 ? 400
  //               : Number(targetUser.total_buy_token) + Number(airdroped) >= 50000 && Number(targetUser.total_buy_token) + Number(airdroped) < 60000 ? 500
  //                 : Number(targetUser.total_buy_token) + Number(airdroped) >= 60000 && Number(targetUser.total_buy_token) + Number(airdroped) < 70000 ? 600
  //                   : Number(targetUser.total_buy_token) + Number(airdroped) >= 70000 && Number(targetUser.total_buy_token) + Number(airdroped) < 80000 ? 700
  //                     : Number(targetUser.total_buy_token) + Number(airdroped) >= 80000 && Number(targetUser.total_buy_token) + Number(airdroped) < 90000 ? 800
  //                       : Number(targetUser.total_buy_token) + Number(airdroped) >= 90000 && Number(targetUser.total_buy_token) + Number(airdroped) < 100000 ? 900
  //                         : Number(targetUser.total_buy_token) + Number(airdroped) >= 100000 && Number(targetUser.total_buy_token) + Number(airdroped) < 110000 ? 1000
  //                           : Number(targetUser.total_buy_token) + Number(airdroped) >= 110000 && Number(targetUser.total_buy_token) + Number(airdroped) < 120000 ? 1100
  //                             : Number(targetUser.total_buy_token) + Number(airdroped) >= 120000 && Number(targetUser.total_buy_token) + Number(airdroped) < 130000 ? 1200
  //                               : Number(targetUser.total_buy_token) + Number(airdroped) >= 130000 && Number(targetUser.total_buy_token) + Number(airdroped) < 140000 ? 1300
  //                                 : Number(targetUser.total_buy_token) + Number(airdroped) >= 140000 && Number(targetUser.total_buy_token) + Number(airdroped) < 150000 ? 1400
  //                                   : Number(targetUser.total_buy_token) + Number(airdroped) >= 150000 && Number(targetUser.total_buy_token) + Number(airdroped) < 160000 ? 1500
  //                                     : ''
  //     )
  //   } else {
  //     setTargetAmount(airdroped)
  //     setTargetReward('')
  //   }
  // }, [targetUser, airdroped])
  console.log(targetUser.total_buy_token, 'targetUser.total_buy_token')
  console.log(airdroped, 'targetUser.total_buy_token11')
  useEffect(() => {
    const totalBuyTokens = Number(targetUser.total_buy_token);
    const airdropTokens = Number(airdroped);
    let reward = 0;

    console.log(totalBuyTokens, 'totalBuyTokens');
    console.log(airdropTokens, 'airdropTokens');

    // Calculate total tokens including both bought and airdropped tokens
    const totalTokens = totalBuyTokens + airdropTokens;
    console.log(totalTokens, 'totalTokens');

    // Determine reward based on different conditions
    if (totalTokens >= 10000) {
      const previousThreshold = Math.floor(totalBuyTokens / 10000);
      const currentThreshold = Math.floor(totalTokens / 10000);

      reward = (currentThreshold - previousThreshold) * 100;

      console.log(previousThreshold, 'previousThreshold');
      console.log(currentThreshold, 'currentThreshold');
      console.log(reward, 'calculated reward');
    }

    reward = Math.max(reward, 0);
    reward = Math.min(reward, 1500);

    console.log(targetUser?.bonus, 'final reward');
    if (getClaimdata[0]?.total_claim_bonus + reward <= 1500) {
      setTargetReward(reward);
    }
  }, [targetUser, airdroped]);

  console.log(GetPhaselist, "phaseLift", targetUser);

  useEffect(() => {
    dispatch(GetActiveRefundFun({ dispatch }))
  }, [])

  const usdtDataStatic = {
    id: 1,
    usdt_balance: "1",
    iff_token: "1000",
    status: "INACTIVE",
  };

  useEffect(() => {
    if (!usdtData) {
      setUsdData(usdtDataStatic)
    }
  }, [usdtData])
  console.log(targetUser?.ALL_USDT, 'targetUseralllll');
  // console.log(targetAmountAdmin - targetUser?.ALL_USDT, 'targetAmountAdmin - targetUser?.ALL_USDT');
  // console.log(targetAmountAdmin, 'targetAmountAdmin');


  const validate = () => {
    const errors = {};
    if (!usdtAmount) {
      errors.usdtAmount = "Please enter a valid USDT amount.";
    }
    const totalUSDT = Number(targetUser?.ALL_USDT || 0) + Number(usdtAmount || 0);
    if (buyOrSell === 'buy' && totalUSDT > targetAmountAdmin) {
      const remainingAmount = Math.round((targetAmountAdmin - targetUser?.ALL_USDT) * 100) / 100;
      // errors.usdtAmount = `Please enter a valid value. You can buy only ${remainingAmount} USD.`;
      errors.usdtAmount = `Due to high demand, there’s a limit of ${remainingAmount} USDT available for sale during this phase`;
    }
    if (!iffAmount) {
      errors.iffAmount = "Please enter a valid IFF amount.";
    }
    if (!selectCheckbox.termsandconditions) {
      errors.termsandconditions = "Please accept our Terms and Conditions.";
    }
    if (!selectCheckbox.privacypolicy) {
      errors.privacypolicy = "Please accept our Privacy Policy.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleClose = () => {
    setShow(false);
    // setBuyOrSell("");
    setUsdtError("");
    setIffError("");
    // setUsdtAmount("");
    // setIffAmount("");
    setErrors("");
  };

  const handleShow = (action) => {
    setShow(true);
    setActiveBuySell('')
    setBuyOrSell(action);
    setTokenAmount("");
    setUsdtToken("");
    setUsdtAmount("");
    setIffAmount("");
    setErrors('')
    setAirdroped('')
    setTargetReward('')
    setTargetAmount('')
    setSelectCheckbox({
      termsandconditions: false,
      privacypolicy: false,
    })
    dispatch(GetActiveRefundFun({ dispatch }))
  };

  useEffect(() => {
    if (value) {
      handleShow('buy')
      setIffAmount(value);
      if (usdtData) {
        setUsdtAmount(
          (usdtData?.usdt_balance * Number(value)) / usdtData?.iff_token
        );
        if (value >= 250 && value < 500) {
          setAirdroped(Number(value) + (Number(value) * 1 / 100));
        } else if (value >= 500 && value < 1000) {
          setAirdroped(Number(value) + (Number(value) * 2 / 100));
        } else if (value >= 1000) {
          setAirdroped(Number(value) + (Number(value) * 5 / 100));
        }
        if (Number(value) >= 250 && Number(value) < 500) {
          setRewardPercentage(1)
        } else if (Number(value) >= 500 && Number(value) < 1000) {
          setRewardPercentage(2)
        } else if (Number(value) >= 1000) {
          setRewardPercentage(5)
        } else {
          setRewardPercentage(0)
        }
      }
    }
  }, [value, usdtData])
  useEffect(() => {
    dispatch(GetPhaseHandler({ dispatch }));
  }, []);

  useEffect(() => {
    dispatch(Getlatestuser({ dispatch }));
  }, [claimaddreess]);
  useEffect(() => {
    dispatch(GetlatestuserAll({ dispatch }));
  }, []);
  const handleInputChange = (e) => {
    let { name, value } = e.target;

    value = value.replace(/[^\d.]/g, "");

    let newIffAmount = 0;
    let newUsdt = 0;
    let newAirdroped = 0;

    if (name === "busd") {
      const numericValue = parseFloat(value) || 0;

      newIffAmount = (numericValue * Number(usdtData?.iff_token)) / Number(usdtData?.usdt_balance);

      if (newIffAmount >= 250 && newIffAmount < 500) {
        newAirdroped = newIffAmount + newIffAmount * 0.01;
      } else if (newIffAmount >= 500 && newIffAmount < 1000) {
        newAirdroped = newIffAmount + newIffAmount * 0.02;
      } else if (newIffAmount >= 1000) {
        newAirdroped = newIffAmount + newIffAmount * 0.05;
      } else {
        newAirdroped = newIffAmount;
      }

      setUsdtAmount(value);
      setTokenAmount(numericValue);
      setUsdtToken((numericValue * usdtData?.iff_token) / usdtData?.usdt_balance);

    } else if (name === "iff") {
      newIffAmount = parseFloat(value) || 0;
      newAirdroped = newIffAmount;
      newUsdt = (Number(usdtData?.usdt_balance * value) / Number(usdtData?.iff_token));

      setUsdtToken(newUsdt.toFixed(2));
      setTokenAmount(newUsdt);
      setIffAmount(newIffAmount);
      setUsdtAmount(newUsdt);
    }

    setIffAmount(newIffAmount);
    setAirdroped(newAirdroped);

    if (newIffAmount >= 250 && newIffAmount < 500) {
      setRewardPercentage(1);
    } else if (newIffAmount >= 500 && newIffAmount < 1000) {
      setRewardPercentage(2);
    } else if (newIffAmount >= 1000) {
      setRewardPercentage(5);
    } else {
      setRewardPercentage(0);
    }

    setUsdtError("");
    setIffError("");
    setErrors({});
  };

  // const handleInputChange = (e) => {
  //   let { name, value = 0 } = e.target;
  //   value = value.replace(/[^\d.]/g, "")
  //   let numericValue = parseFloat(value) || 0;
  //   let newIffAmount = 0
  //   let newUsdt = 0
  //   let newAirdroped = 0
  //   if (name === "busd") {
  //     newUsdt = value;
  //     newIffAmount = newUsdt * Number(usdtData?.iff_token) / Number(usdtData?.usdt_balance);
  //     if (newIffAmount >= 250 && newIffAmount < 500) {
  //       newAirdroped = ((Number(newIffAmount) + Number(newIffAmount * 0.01)))
  //     } else if (newIffAmount >= 500 && newIffAmount < 1000) {
  //       newAirdroped = ((Number(newIffAmount) + Number(newIffAmount * 0.02)))
  //     } else if (newIffAmount >= 1000) {
  //       newAirdroped = ((Number(newIffAmount) + Number(newIffAmount * 0.05)))
  //     } else {
  //       newAirdroped = ((newIffAmount))
  //     }
  //     setTokenAmount(value);
  //     setUsdtToken(
  //       (Number(value) * usdtData?.iff_token) / usdtData?.usdt_balance
  //     );
  //   }
  //   else if (name === "iff") {
  //     newIffAmount = parseFloat(value) || 0;
  //     newAirdroped = parseFloat(value) || 0;
  //     newUsdt = (Number(usdtData?.usdt_balance * value) / Number(usdtData?.iff_token));
  //     // / Number(usdtData?.iff_token));
  //     setUsdtToken(newUsdt.toFixed(2));
  //     setTokenAmount(Number(usdtData?.usdt_balance * value) / Number(usdtData?.iff_token));
  //   }
  //   setIffAmount(newIffAmount);
  //   setUsdtAmount(newUsdt);
  //   setAirdroped(newAirdroped);
  //   if (newIffAmount >= 250 && newIffAmount < 500) {
  //     setRewardPercentage(1);
  //   } else if (newIffAmount >= 500 && newIffAmount < 1000) {
  //     setRewardPercentage(2);
  //   } else if (newIffAmount >= 1000) {
  //     setRewardPercentage(5);
  //   } else {
  //     setRewardPercentage(0);
  //   }
  //   setUsdtError("");
  //   setIffError("");
  //   setErrors({});
  // };

  const deductionPercentage = 12;
  useEffect(() => {
    const calculatedPercentage = iffAmount - (iffAmount * (deductionPercentage / 100));
    const formattedPercentage = parseFloat(calculatedPercentage);
    setsellUsdtPercentage(formattedPercentage.toFixed(6));
  }, [usdtAmount, deductionPercentage]);

  const localMeta = localStorage.getItem('meta');
  const handleSubmit = async () => {
    setActiveBuySell('buy');
    if (!validate()) {
      return;
    }
    let usdtValid = true;
    let iffValid = true;
    if (!usdtAmount) {
      setUsdtError("Please enter a valid USDT amount.");
      usdtValid = false;
    } else {
      setUsdtError("");
    }
    if (!iffAmount) {
      setIffError("Please enter a valid IFF amount.");
      iffValid = false;
    } else {
      setIffError("");
    }
    if (!localMeta) {
      alert('Please Connect Metamask');
      return;
    }
    if (
      usdtValid &&
      iffValid &&
      selectCheckbox.termsandconditions === true &&
      selectCheckbox.privacypolicy === true
    ) {
      try {
        const transaction = await writeContract({
          address: usdtContractAddress,
          abi: usdtContractAbi,
          functionName: 'transfer',
          args: ['0x0Fc09906bC3ebD0bfc69654132a93A02Cb2bd337', Number(usdtAmount) * 10 ** 18],
        });
        dispatch(startLoading(true));
        handleClose();
      } catch (error) {
        console.error("Error performing transaction:", error.message);
      }
    }
  };

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    })

  const hasRun = useRef({ error: false, isConfirmed: false });

  useEffect(() => {
    if (error) {
      dispatch(startLoading(false));
      toast.error(`Transaction rejected please check metamask`);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (error && !hasRun.current.error) {
      hasRun.current.error = true;
      dispatch(startLoading(false));
    }
    if (isConfirmed && !hasRun.current.isConfirmed) {
      hasRun.current.isConfirmed = true;
      if (activeBuySell === 'buy') {
        // let calculatedAmount;
        // if (airdroped + targetUser?.total_buy_token <= targetUser?.target_value) {
        //   calculatedAmount = airdroped + targetUser?.bonus
        // } else {
        //   calculatedAmount = airdroped || iffAmount
        // }
        dispatch(
          IFFTranferHandler({
            dispatch,
            iffAmount: airdroped || iffAmount,
            contractABI,
            contractAddress,
            usdtContractAbi,
            usdtAmount,
            usdtContractAddress,
            claimaddreess
          })
        );
        setShow(true);
        setActiveBuySell('')
        setBuyOrSell('buy');
        setTokenAmount("");
        setUsdtToken("");
        setTargetReward('')
        setTargetAmount('')
        setUsdtAmount("");
        setAirdroped('')
        setIffAmount("");
        setErrors('')
        setSelectCheckbox({
          termsandconditions: false,
          privacypolicy: false,
        })
      } else if (activeBuySell === 'sell') {
        dispatch(SellIFFHandler({
          dispatch,
          iffAmount,
          usdtAmount: sellUsdtPercentage,
          contractABI,
          contractAddress,
          usdtContractAbi,
          usdtContractAddress,
        }));
        // dispatch(startLoading(false));
        setShow(true);
        setActiveBuySell('')
        setBuyOrSell('sell');
        setTokenAmount("");
        setUsdtToken("");
        setTargetReward('')
        setTargetAmount('')
        setUsdtAmount("");
        setIffAmount("");
        setErrors('')
        setSelectCheckbox({
          termsandconditions: false,
          privacypolicy: false,
        })
      }
    }
  }, [isConfirmed, error, activeBuySell, dispatch, iffAmount, contractABI, contractAddress, usdtContractAbi, usdtAmount, usdtContractAddress, claimaddreess]);

  useEffect(() => {
    hasRun.current.isConfirmed = false;
  }, [activeBuySell]);

  const handleSell = async () => {
    setActiveBuySell('sell');
    if (!validate()) {
      return;
    }
    let usdtValid1 = true;
    let iffValid1 = true;
    if (!usdttoken) {
      setIffError("Please enter a valid IFF amount.");
      iffValid1 = false;
    } else {
      setIffError("");
    }
    if (!localMeta) {
      alert('Please Connect Metamask');
      return;
    }
    if (!tokenAmount) {
      setUsdtError("Please enter a valid USDT amount.");
      usdtValid1 = false;
    } else {
      setUsdtError("");
    }
    if (
      usdtValid1 &&
      iffValid1 &&
      selectCheckbox.termsandconditions === true &&
      selectCheckbox.privacypolicy === true
    ) {
      try {
        const transaction = await writeContract({
          address: contractAddress,
          abi: contractABI,
          functionName: 'transfer',
          args: ['0x0Fc09906bC3ebD0bfc69654132a93A02Cb2bd337', Number(iffAmount) * 10 ** 18],
        });
        dispatch(startLoading(true));
        handleClose();
      } catch (error) {
        console.error("Error performing transaction:", error.message);
      }
    }
  };

  useEffect(() => {
    GetPhaselist?.filter((value) => {
      if (value?.status === "ACTIVE") {
        setUsdData(value);
      }
      if (!usdtData) {
        setUsdData(usdtDataStatic);
      }
    });
  }, [GetPhaselist]);

  const handleSelectCheckBox = (e, checkedTo) => {
    if (checkedTo === "PrivacyPolicy") {
      setSelectCheckbox((prevState) => ({
        ...prevState,
        privacypolicy: e.target.checked,
      }));
    } else {
      setSelectCheckbox((prevState) => ({
        ...prevState,
        termsandconditions: e.target.checked,
      }));
    }
    setErrors({});
  };

  const openPDFPrivacy = () => {
    const filePath = Privacypdf;
    window.open(filePath, "_blank");
  };

  const openPDFTermsandConditions = () => {
    const filePath = termsandconditionspdf;
    window.open(filePath, "_blank");
  };

  // useEffect(() => {
  //   setsellUsdtPercentage(usdtAmount - (usdtAmount * (deductionPercentage / 100)));
  // }, [usdtAmount])

  useEffect(() => {
    if (iffAmount < 250) {
      setRewarState(250 - iffAmount)
    }
  }, [iffAmount]);


  function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,  // Set minimumFractionDigits to 2
      maximumFractionDigits: 2   // Set maximumFractionDigits to 2
    }).format(value);
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="border-bottom buy_iff_header">
            <h1>Buy <img src={ifflogo} style={{ height: '40px' }} /> IFF Token</h1>
          </div>
          <div className="border-bottom123">
            <h5 className="d-flex flex-wrap justify-content-around border-bottom p-2 font_css" >
              {GetPhaselist?.map((val) => {
                if (val.status == "ACTIVE") {
                  return (
                    <>
                      {/* <p>Status: {val.status}</p> */}
                      <div style={{ width: "65%", display: "flex", marginTop: "18px" }}>
                        <p style={{ paddingLeft: "0px" }}>IFF Token: {val.iff_token}</p>
                        <p style={{ paddingLeft: "110px" }}>USDT Balance: {val.usdt_balance}</p>
                      </div>
                    </>
                  );
                }
              })}
            </h5>
          </div>
          <div className='row'>
            <div className='col-lg-8 col-sm-12 class_history_lg'>
              <TranscationHistory />
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className="box-shadow-attractive">
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
                  {buyOrSell == 'buy' ?
                    <div style={{ display: 'flex' }}>
                      {/* <label className={usdtData?.status == 'ACTIVE' ? "pulsing-dot" : "pulsing-dot_Inactive"}></label> */}
                      <label
                        className={
                          usdtData?.status === 'ACTIVE' ||
                            targetUser?.ALL_USDT < targetAmountAdmin
                            ? "pulsing-dot"
                            : "pulsing-dot_Inactive"
                        }
                      ></label>
                      <label className={usdtData?.status == 'ACTIVE' || targetUser?.ALL_USDT < targetAmountAdmin
                        ? "pulsing-doted" : "pulsing-doted-dot"}
                        style={{
                          fontSize: '15px', fontWeight: '400', color: 'black',
                          marginTop: targetUser?.target_status != "active" ? "12px" : "12px",
                          minWidth: targetUser?.target_status != "active" ? "90px" : "90px",
                          minWidth: '150px',
                          marginLeft: usdtData?.status == "ACTIVE" ? "" : claimaddreess != null ? "10px" : "4px",
                          marginLeft: targetUser?.total_usdt >= targetAmountAdmin ? "1px" : "4px",
                        }}>
                        {usdtData?.status == 'ACTIVE' ||
                          targetUser?.ALL_USDT < targetAmountAdmin ? `Phase ${usdtData?.index + 1} Live` : 'No Phase Active'}
                      </label>
                    </div> :
                    <div style={{ justifyContent: 'start', display: 'flex', width: "100%" }}>
                      <p className={refundStatus[0]?.status == "1" ? "pulsing-dot" : "pulsing-dot_Inactive"} style={buyOrSell === "refund" ? { width: "50%" } : {}}></p>
                      <p className={refundStatus[0]?.status == "1" ? "pulsing-doted" : "pulsing-doted-dot"}
                        style={{
                          fontSize: '15px', fontWeight: '400',
                          minWidth: refundStatus[0]?.status != "1" ? "192px" : "160px",
                          marginTop: refundStatus[0]?.status != "1" ? "10px" : "10px", color: 'black'
                        }}>
                        {refundStatus[0]?.status == "1" ? "Refunds are available" : "Refund option unavailable"}
                        {/* This Sale Ended. Stay Tuned! */}
                      </p>
                    </div>}
                  <div style={{ justifyContent: 'end', display: 'flex', width: '100%', marginTop: "10px" }}>
                    <p style={{ marginRight: "-10px", fontSize: '15px', display: 'flex', color: 'black' }}>RAISED: {targetUserAll?.ALL_USDT ? formatCurrency(targetUserAll?.ALL_USDT) : '$0'}<span style={{ fontSize: '20px' }}></span></p>
                  </div>
                </div>
                <div>
                  <div className="card" style={{ height: '10px', position: 'relative' }}>
                    <ProgressBar value={newvalue && newvalue}></ProgressBar>
                  </div>
                </div>
                {/* {GetPhaselist?.map((val) => {
                  if (val.status == "ACTIVE") {
                    return (
                      <>
                        <div style={{ justifyContent: 'end', display: 'flex' }}>
                          <p style={{ fontSize: '15px', marginTop: "10px", marginBottom: "10px", color: 'black' }}>TARGET: {val?.targetValue ? formatCurrency(val?.targetValue) : '$0'}</p>
                        </div>
                      </>
                    );
                  }

                })} */}
                <div style={{ justifyContent: 'end', display: 'flex' }}>
                  <p style={{ fontSize: '15px', marginTop: "10px", marginBottom: "10px", color: 'black' }}>TARGET: {targetAmountAdmin ? formatCurrency(targetAmountAdmin) : '$0'}</p>
                </div>
                <div className="row" style={{ marginBottom: "20px", marginTop: !GetPhaselist ? "10px" : "0" }}>
                  <div className="col-12" style={{ backgroundColor: "#61a1fe", padding: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '310px', margin: '0 auto' }}>
                    <div style={{ flex: 1, color: "white", textAlign: "center", fontSize: 12 }}>
                      Current Price
                      <br />
                      ${usdtData?.usdt_balance / usdtData?.iff_token}
                    </div>
                    <div style={{ borderLeft: '1px solid white', height: '100%', margin: '0 10px' }}></div>
                    <div style={{ flex: 1, color: "white", textAlign: "center", fontSize: 12 }}>
                      Final Phase Price
                      <br />
                      $3.20
                      {/* {Math.round(usdtData?.usdt_balance / usdtData?.iff_token)} */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button variant="primary" onClick={() => handleShow("buy")} style={{ width: '100%' }} disabled={buyOrSell == 'buy'}>
                      Buy
                    </Button></div>
                  <div className="col-6">
                    <Button
                      variant="primary"
                      onClick={() => handleShow("sell")}
                      style={{ width: '100%' }}
                      disabled={buyOrSell == 'sell'}
                    >
                      Refund
                    </Button>
                  </div>
                </div>

                <div style={{ marginTop: '10px' }}>
                  {buyOrSell === "buy" ? (
                    <div>
                      <div>
                        <label>Pay with USDT</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            name="busd"
                            className="investment_modal_css"
                            onChange={handleInputChange}
                            value={usdtAmount}  // Directly use `usdtAmount` state without converting it to a number
                          />
                          <p className="text-danger" style={{ fontSize: "12px" }}>{errors.usdtAmount}</p>
                        </div>
                      </div>
                      <div className="my-3">
                        <label>Buy IFF</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            className="investment_modal_css"
                            name="iff"
                            onChange={handleInputChange}
                            // value={airdroped}
                            value={isNaN(Number(airdroped)) ? 0 : Number(airdroped)}
                          />
                          <p className="text-danger">
                            {errors.iffAmount ? errors.iffAmount : rewardPercenteage > 0 ? (
                              <p style={{ color: 'gray' }}>Activated Bonus: Airdrop Reward +{rewardPercenteage}%</p>
                            ) : (
                              <p style={{ color: 'gray', fontSize: '12px' }}>Activate Bonus: Add {rewardState && rewardState} IFF more to get Airdrop Reward.</p>
                            )}
                          </p>
                          {targetUser?.bonus == 1500 && airdroped ? <p style={{ backgroundColor: "green", color: "white", width: "340px", display: "flex", borderRadius: '5px', height: '30px', textAlign: 'center', alignItems: 'center', paddingLeft: '10px' }}>Maximum VIP Bonus Reward Reached</p> : targetReward && targetReward ? <p style={{ backgroundColor: "green", color: "white", width: "340px", display: "flex", borderRadius: '5px', height: '30px', textAlign: 'center', alignItems: 'center', paddingLeft: '10px' }}>Activated VIP Bonus Reward: {targetReward && targetReward} IFF </p> : ''}
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox1"
                            checked={selectCheckbox?.termsandconditions}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "TermAndCondition")
                            }
                            value={buyOrSell === "buy" ? iffAmount : usdttoken}
                          />
                          <a
                            onClick={openPDFTermsandConditions}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Terms and Conditions.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.termsandconditions}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox2"
                            checked={selectCheckbox?.privacypolicy}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "PrivacyPolicy")
                            }
                          />
                          <a
                            onClick={openPDFPrivacy}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Privacy Policy.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.privacypolicy}</p>
                        </div>
                        {metaaddress[0] ? <Button
                          className="w-100"
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={usdtData?.status == 'INACTIVE' || targetUser?.ALL_USDT >= buyInactiveAmount}
                        >
                          <p className="my-2">Buy IFF</p>
                        </Button> :
                          //  <Button
                          //   className="w-100"
                          //   variant="primary"
                          // >
                          <YourApp />
                          // </Button>
                        }
                      </div>

                    </div>
                  ) : (
                    <div>
                      <div className="my-2">
                        <label>IFF</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            className="investment_modal_css"
                            name="iff"
                            onChange={handleInputChange}
                            value={iffAmount}
                          />
                          <p className="text-danger">{errors.iffAmount}</p>
                        </div>
                      </div>
                      <div>
                        <label>Refund USDT</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            name="busd"
                            className="investment_modal_css"
                            onChange={handleInputChange}
                            value={isNaN(Number(sellUsdtPercentage)) ? 0 : Number(sellUsdtPercentage)}
                          />
                          <p className="text-danger">
                            {errors.usdtAmount ? errors.usdtAmount : usdtAmount ? (
                              <p style={{ color: 'gray' }}>A transaction fee of 12% has been applied.</p>
                            ) : (
                              <p style={{ color: 'gray', fontSize: '12px' }}>A transaction fee of 12% will be applied.</p>
                            )}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox1"
                            checked={selectCheckbox?.termsandconditions}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "TermAndCondition")
                            }
                            value={buyOrSell === "buy" ? iffAmount : usdttoken}
                          />
                          <a
                            onClick={openPDFTermsandConditions}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Terms and Conditions.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.termsandconditions}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox2"
                            checked={selectCheckbox?.privacypolicy}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "PrivacyPolicy")
                            }
                          />
                          <a
                            onClick={openPDFPrivacy}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Privacy Policy.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.privacypolicy}</p>
                        </div>
                      </div>
                      {metaaddress[0] ? <Button
                        className="w-100"
                        variant="primary"
                        onClick={handleSell}
                        disabled={refundStatus[0]?.status == '0'}
                      >
                        <p className="my-2">Refund USDT</p>
                      </Button> :
                        //  <Button
                        //   className="w-100"
                        //   variant="primary"
                        // >
                        <YourApp />
                        // </Button>
                      }
                    </div>
                  )}
                </div>
                <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                  <a>  {buyOrSell === "buy" ? 'HOW TO BUY?' : 'HOW TO GET A REFUND?'}</a>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-sm-12  class_history_sm' style={{ marginTop: '30px' }}>
              <TranscationHistory />
            </div>
          </div>
          <div className="d-flex my-2">
            <div>
            </div>
          </div>
        </div >
      </div >
      {/* <InvestCard targetReward={targetReward} /> */}
    </>
  );
}

export default InvestmentModal;
